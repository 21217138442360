<div class="container" style="padding-top: 10%;">
  <div class="col-md-4" style="float:left" *ngFor="let course of cartCourseList">
      <div class="box-shadow">
          <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <img src="{{imageURL}}{{course.image}}" width="100px" height="100px" /> 
              </div>
                <div class="col-md-6">
                    <p class="card-text">{{course.Name}}</p>
                    <p class="card-text">Rs {{course.price}}</p>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="btn-group">
                          <button (click)="removeCourse(course)" class="btn btn-sm btn-danger mr-1">- (Remove From Cart)</button>
                          <button (click)="checkout(course)" class="btn btn-sm btn-success mr-1">Checkout</button>
                        </div>
                    </div>
                </div>

            </div>
          </div>
      </div>
  </div>
</div>
