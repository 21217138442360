<style>
.fof h1{
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
}
</style>
<div class="container" style="padding-top: 10%;">
<div id="container">
    <div>

            <img sr="alert.jpg" />

    </div>
    <div class="row">

            <h1 style="color: red;">oh no, something went wrong.</h1>

    </div>
</div>
</div>