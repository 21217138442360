<div class="col-lg-6 col-md-6 col-sm-12 col-xs-5 offset-lg-3 offset-md-3">
    <div class="card">
        <h4 class="card-header">Register</h4>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="Course">Type of Professional</label>
                    <select (change)="onChange($event.target.value)" class="form-control">
                    <option value="0">--All--</option>
                    <option *ngFor="let type of types" value={{type.Name}}>
                        {{type.Name}}
                    </option>
                    </select>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="firstName">First Name</label>
                            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                <div *ngIf="f.firstName.errors.required">First Name is required</div>
                            </div>
        
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label for="lastName">Last Name</label>
                            <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                            </div>
        
                        </div>
                    </div>
                    
                </div>
                <div class="form-group">
                    <label for="username">Email Id</label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
                <hr>
                <div class="form-group text-center">
                        <button [disabled]="loading" class="btn btn-success">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Register
                        </button>
                        &nbsp;
                        <a routerLink="/login" class="btn btn-danger">Cancel</a>
                </div>
            </form>
        </div>
    </div>
</div>