import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../../models/user';
import { C } from '../../models/c';
import { Course } from '../../models/course';

@Injectable({ providedIn: 'root' })
export class AccountService {
    apiUrl= '';
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        public http: HttpClient) {
        this.apiUrl = environment.apiUrl;
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();

    }
    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username, password) {
        return this.http.post<User>(`${this.apiUrl}/users/authenticate`, { username, password })
             .pipe(map(user => {
                if(user.id != 0)
                {
                    localStorage.setItem('user', JSON.stringify(user));
                    this.userSubject.next(user);
                }
             }));

    }

    logout() {
        debugger;
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.router.navigate(['/account/login']);
    }
    /////////////////////////Course//////////////////////////////////////////
    registerCourse(id: number, c: Course) {
        return this.http.post(`${this.apiUrl}/courses/register/${id}`, c);
    }

    getAllCourse(role: string, id : number) {
        return this.http.get<Course[]>(`${this.apiUrl}/courses/getAll/${role}/${id}`);
    }

    getByIdCourse(id: number) {
        return this.http.get<Course>(`${this.apiUrl}/courses/getById/${id}`);
    }

    updateCourse(id, params) {
        return this.http.put(`${environment.apiUrl}/courses/update/${id}`, params)
            .pipe(map(x => {
                return x;
            }));
    }

    deleteCourse(id: number) {
        return this.http.delete(`${environment.apiUrl}/courses/del/${id}`)
            .pipe(map(x => {
                return x;
            }));
    }

    ////////////////Course Details////////////////////////////////////////////
    registerC(c: C) {
        return this.http.post(`${this.apiUrl}/cs/register`, c);
    }

    getAllC(id: string) {
        return this.http.get<C[]>(`${this.apiUrl}/cs/getAll/${id}`);
    }

    getByIdC(id: number) {
        return this.http.get<C>(`${this.apiUrl}/cs/getById/${id}`);
    }

    updateC(id, params) {
        return this.http.put(`${this.apiUrl}/cs/update/${id}`, params)
            .pipe(map(x => {
                return x;
            }));
    }

    deleteC(id: number) {
        return this.http.delete(`${this.apiUrl}/cs/del/${id}`)
            .pipe(map(x => {
                return x;
            }));
    }
    ////////////////////////USER///////////////////////////////////////////////
    register(user: User) {
        return this.http.post(`${this.apiUrl}/users/register`, user);
    }

    getAll(role: string,id: number) {
        return this.http.get<User[]>(`${this.apiUrl}/users/getAll/${role}/${id}`);
    }

    getById(id: number) {
        return this.http.get<User>(`${this.apiUrl}/users/getById/${id}`);
    }

    update(id, newmodel) {
        return this.http.put(`${this.apiUrl}/users/update/${id}`, newmodel)
        
            .pipe(map(x => {
                return x;
            }));
    }

    delete(id: number) {
        return this.http.delete(`${this.apiUrl}/users/del/${id}`)
            .pipe(map(x => {
                return x;
            }));
    }
    ///////
    buyed(id: number, buyed: Course)
    {
        debugger;
        return this.http.post(`${this.apiUrl}/users/buyed/${id}`, buyed);
    }
    ///////////
    getAllCourseByGroup(group: string) {
        return this.http.get<Course[]>(`${this.apiUrl}/courses/GetAllCourseByGroup/${group}`);
    }
}