import { Component, Inject } from '@angular/core';
import { User } from '../models/user';
import { AccountService } from '../account/shared/account.service';
import { AlertService } from '../shared/alert-service.component';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate} from '@angular/animations';

@Component({
  selector: 'home',
  templateUrl: 'home.component.html',
   styleUrls: ['home.component.css'],
   animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class HomeComponent {
  menuState:string = 'out';

  title = 'coachingapp';
  courses = null;
  user: User;
  cartCourseList = [];
  dbcourses: {courses: any};
  apiUrl= environment.apiUrl;
  //Environment Variables
  imageURL = environment.imageURL;
  soft= '';
  design= '';
  web= '';
  db ='';
  softCourse1= '';
  designCourse1='';
  webCourse1='';
  dbCourse1='';
  softCourse2='';
  designCourse2='';
  webCourse2='';
  dbCourse2='';
  softCourse1desc= '';
  designCourse1desc='';
  webCourse1desc=   '';
  dbCourse1desc=    '';
  softCourse2desc=  '';
  designCourse2desc='';
  webCourse2desc=   '';
  dbCourse2desc=   '';

  lecturer='';
  copyright= '';
  copyrightlink = '';

  name:string;
  email:string;
  p: any;
  groups: any;
  
  constructor(@Inject(DOCUMENT) document, private accountService: AccountService, private alertService: AlertService,private route: ActivatedRoute,
  private router: Router) {
    //this.user = this.accountService.userValue;
    this.soft= environment.soft;
    this.design= environment.design;
    this.web= environment.web;
    this.db =environment.db;
    this.softCourse1= environment.softCourse1;
    this.designCourse1=environment.designCourse1;
    this.webCourse1=environment.webCourse1;
    this.dbCourse1=environment.dbCourse1;
    this.softCourse2=environment.softCourse2;
    this.designCourse2=environment.designCourse2;
    this.webCourse2=environment.webCourse2;
    this.dbCourse2=environment.dbCourse2;

    this.softCourse1desc=  environment.softCourse1desc;
    this.designCourse1desc=environment.designCourse1desc;
    this.webCourse1desc=   environment.webCourse1desc;
    this.dbCourse1desc=    environment.dbCourse1desc;
    this.softCourse2desc=  environment.softCourse2desc;
    this.designCourse2desc=environment.designCourse2desc;
    this.webCourse2desc=   environment.webCourse2desc;
    this.dbCourse2desc=    environment.dbCourse2desc;


    this.lecturer=environment.lecturer;
    this.copyright= environment.copyright;
    this.copyrightlink = environment.copyrightlink;
  }

  ngOnInit() {
    this.accountService.getAllCourse('All', 0)
       .pipe(first())
        .subscribe(courses =>{ this.courses = courses;
          this.groups =  [...new Set(courses.map(item => item.group))];
        });

    this.cartCourseList = JSON.parse(localStorage.getItem('cartCourseList')) ? JSON.parse(localStorage.getItem('cartCourseList')) : [] ;
    this.user = this.accountService.userValue;
}
toggleMenu() {
    // 1-line if statement that toggles the value:
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  addCourseToCart(course) {
    const courseExistInCart = this.cartCourseList ? this.cartCourseList.find(({Name}) => Name === course.Name) : 0 ; // find product by name
    if (!courseExistInCart) {
      this.cartCourseList.push({...course, num:1}); // enhance "porduct" opject with "num" property
      localStorage.setItem('cartCourseList', JSON.stringify(this.cartCourseList));
      this.alertService.success('Course added successfully', { keepAfterRouteChange: true });
      return;
    }
    courseExistInCart.num += 1;
  }

  RegisterStudent(){
    this.name = (<HTMLInputElement>document.getElementById('studentname')).value;
    this.email = (<HTMLInputElement>document.getElementById('studentemail')).value;
    this.router.navigate(['/register/'+this.name + '/'+ this.email]);
  }
  filterCourse(){
    const fil = (<HTMLInputElement>document.getElementById('filterable')).value;
    this.accountService.getAllCourse('All', 0)
      .pipe(first())
     .subscribe(courses => {
      this.courses = courses.filter(v => {
        return v.name.toLowerCase().search(fil.toLowerCase()) > -1;
          
      });
    });
    
  }
  FilterByGroup(group: string){
    this.accountService.getAllCourseByGroup(group)
    .pipe(first())
   .subscribe(courses => {
    this.courses = courses.filter(v => {
      return v.group.toLowerCase().search(group.toLowerCase()) > -1;
        
    });
  });
  }
}