import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AlertService } from 'src/shared/alert-service.component';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { Course } from 'src/models/course';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'cart',
  templateUrl: 'cart.component.html',
  styleUrls: ['cart.component.css']
})

export class CartComponent  {
imageURL = environment.imageURL;  
course : any;
cartCourseList : [];
takePaymentResult: string;
priceId = "price_1JMCBNSHNr451wa0ERQYaFQD";
product = {
  title: "Angular 7",
  subTitle: "UI Developer Basic Course",
  description:
    "From Novice To Ninja",
  price: 399,
};
quantity = 1;
stripePromise = loadStripe(environment.stripe_key);
appURL = environment.appURL;

constructor(private http:HttpClient,private alertService: AlertService) {
}

ngOnInit(){
    this.cartCourseList = JSON.parse(localStorage.getItem('cartCourseList'));
  }
  removeCourse(course) {
    //this.cartCourseList = this.cartCourseList.filter(({Name}) => Name !== course.Name)
    localStorage.removeItem('cartCourseList');
    localStorage.setItem('cartCourseList', JSON.stringify(this.cartCourseList.filter(({Name}) => Name !== course.Name)));
    this.cartCourseList = JSON.parse(localStorage.getItem('cartCourseList'));
   }
   async checkout(course: Course) {
    // Call your backend to create the Checkout session.
    localStorage.setItem('sessionId', Guid.create().toString());
    localStorage.setItem('buyed',JSON.stringify(course));
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await this.stripePromise;
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: [{ price: course.priceId, quantity: this.quantity}],
      successUrl: `${this.appURL}/success/` + localStorage.getItem('sessionId'),// Call your backend to update the Checkout session.
      cancelUrl: `${this.appURL}/failure/`//,// Call your backend to update the Checkout session.
      //sessionId: 'abc_Session123',
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    if (error) {
      console.log(error);
    }

  }

}