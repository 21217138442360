import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-failure',
  templateUrl: 'failure.component.html'
})
export class FailureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    localStorage.removeItem('sessionId');
  }

}
