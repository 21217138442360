import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService} from '../shared/account.service';
import { AlertService } from '../../shared/alert-service.component';

@Component({
    templateUrl: 'register.component.html',
    styleUrls:['register.component.css']
})

export class RegisterComponent implements OnInit {
    form: FormGroup;
    loading = false;
    submitted = false;
    sub;
    name: string;
    email: string;
    types: any;

    constructor(
        private formBuilder: FormBuilder,
        private _Activatedroute:ActivatedRoute,
        private _router:Router,
        private accountService: AccountService,
        private alertService: AlertService
    ) { 
        this.types = [
            {Name: "Teacher"},{Name: "Student"}
        ];

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            role:  ['', Validators.required]
        });

        this.sub=this._Activatedroute.paramMap.subscribe(params => { 
            this.name = params.get('name'); 
            this.email = params.get('email'); 
            this.f.firstName.setValue(this.name);
            this.f.username.setValue(this.email);
         });
    }
    onChange(event) {
        if (event == "0")
            alert("Please Select a Role");
        else
            this.f.role.setValue(event);
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.accountService.register(this.form.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', { keepAfterRouteChange: true });
                    this._router.navigate(['/login']);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}