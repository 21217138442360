import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from 'src/about/about.component';
import { LoginComponent } from 'src/account/login/login.component';
import { RegisterComponent } from 'src/account/register/register.component';
import { CartComponent } from 'src/cart/cart.component';
import { FailureComponent } from 'src/cart/failure.component';
import { SuccessComponent } from 'src/cart/success.component';
import { CoursesListComponent } from 'src/courses/courses-list/courses-list.component';
import { PrivacyComponent } from 'src/privacy/privacy.component';
import { TermsComponent } from 'src/terms/terms.component';
import { TestimonialsComponent } from 'src/testimonials/testimonials.component';

import { HomeComponent } from '../home/home.component';
import { AuthGuard } from '../shared/auth-guard';

const accountModule = () => import('../account/account.module').then(x => x.AccountModule);
const usersModule = () => import('../users/users.module').then(x => x.UsersModule);
const coursesModule = () => import('../courses/courses.module').then(x => x.CoursesModule);
const csModule = () => import('../cs/cs.module').then(x => x.CsModule);

const routes: Routes = [
    { path: '', component: HomeComponent},
    { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard] },
    { path: 'courses', loadChildren: coursesModule, canActivate: [AuthGuard] },
    { path: 'cs', loadChildren: csModule, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'cart', component: CartComponent, canActivate: [AuthGuard] },
    { path: 'success/:sessionid', component: SuccessComponent, canActivate: [AuthGuard] },
    { path: 'failure', component: FailureComponent, canActivate: [AuthGuard] },
    { path: 'register', component: RegisterComponent },
    { path: 'register/:name/:email', component: RegisterComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'about', component: AboutComponent },
    {path:"terms",component:TermsComponent},
    {path:"privacy",component:PrivacyComponent},
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }