import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../environments/environment';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
    
  // API url
  uploadURL = environment.apiUrl + '/WeatherForecast/' + 'upload'

  constructor(private http:HttpClient) { }
  
  // Returns an observable
  upload(file):Observable<any> {
      // Create form data
      const formData = new FormData(); 
        
      // Store form name as "file" with file data
      formData.append("file", file, file.name);
        
      // Make http post request over api
      // with formData as req
      return this.http.post(this.uploadURL, formData)
  }
}