import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService} from '../shared/account.service';
import { AlertService } from '../../shared/alert-service.component';
import { User } from 'src/models/user';
import 'src/assets/login-animation.js';

@Component({ templateUrl: 'login.component.html', styleUrls: ['login.component.css'] })
export class LoginComponent implements OnInit {
    //form: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    user: User;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService
    ) { 

        
    }
    email: string;
    password: string;
  
    ngAfterViewInit() {
      (window as any).initialize();
    }
  
    login(){
      console.log(`email: ${this.email} password: ${this.password}`)
      alert(`Email: ${this.email} Password: ${this.password}`)
    }

    ngOnInit() {
      //  this.form = this.formBuilder.group({
      //      email: ['', Validators.required],
      //      password: ['', Validators.required]
      //  });

        // get return url from route parameters or default to '/'
         this.returnUrl = '/home'; //this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    //get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        console.log('in');
        // reset alerts on submit
        this.alertService.clear();
        debugger;
        // stop here if form is invalid
      // if (this.form.invalid) {
      //      return;
      //  }
        
        this.loading = true;
         this.accountService.login(this.email, this.password)
             .subscribe(
                 data => {
                     debugger;
                     
                     this.user = JSON.parse(localStorage.getItem('user'));
                     if(this.user.id !=0)
                     {
                        this.router.navigate(['/'])
                        .then(() => {
                            window.location.reload();
                        });
                    }                      
                    
                },
                error => {
                    debugger;
                    console.log(error);
                    this.alertService.error(error);
                    
                });
                this.loading = false;
        //this.accountService.login(this.form.controls["username"].value,this.form.controls["password"].value);
        //window.location.reload();
    }
}