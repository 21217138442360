import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
@Component({
    selector: 'terms',
    templateUrl: 'terms.component.html',
     styleUrls: ['terms.component.css']
  })

  export class TermsComponent {
    copyright= '';
    copyrightlink = '';

    constructor(@Inject(DOCUMENT) document,
  private router: Router) {
    this.copyright= environment.copyright;
    this.copyrightlink = environment.copyrightlink;
  }

}

  