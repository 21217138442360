
	<div class="row">
		<div class="col-lg-1 col-md-1 col-xs-1" style="cursor: pointer;" (click)="toggleMenu()" > 
			<i class="fa fa-filter" aria-hidden="true" style="font-size: 25px; color:#d82a4e;"></i>
			<span>Filter By</span>
		</div>
	</div>

<div class="category" [@slideInOut]="menuState">
	<h2 style="padding-top: 10%;padding-left: 15%;font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Filter By Category</h2>
	<ul style="padding-left:10%">
		<li *ngFor="let group of groups" ><button (click)="FilterByGroup(group)" type="button" style="width:50%;color:white;background-color: #d82a4e;border: 0px solid;">{{group}}</button></li>
	  </ul>
</div>

	<!-- categories section -->
	<section class="categories-section">
			<div class="section-title">
				<h2>Online Courses</h2>
			</div>
				<div class="row">
					<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 offset-lg-3 offset-md-3" >
						<div class="input-group">
							<input id="filterable" type="text" placeholder="Course" class="form-control" style="border: 1px solid #d82a4e;">
						</div>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" >
						<button (click)="filterCourse()" type="button" class="btn btn-danger">Search</button>
					</div>
				</div>
				<hr />
			<div class="row">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngFor="let course of courses | paginate:{itemsPerPage: 5, currentPage:p}">
					<div class="categorie-item">
						<img style="text-align: center; height: 200px; width: 100%;" src="{{imageURL}}{{course.image}}" class="img-responsive" />
						<div class="ci-text">
							<h5>{{course.name}}</h5>
							<p>{{course.description}}</p>
							<span>Rs {{course.price}}</span>
                            <p><button (click)="addCourseToCart(course)" class="btn btn-sm btn-success mr-1">+ (Add To Cart)</button></p>
						</div>
					</div>
               </div>
			</div>
			<div class="row">
				<pagination-controls (pageChange)="p=$event"></pagination-controls>
			</div>
	</section>
	<!-- categories section end -->
	<!-- Hero section -->
	<!-- <section class="hero-section set-bg" [style.background-image]="'url(assets/img/bg.jpg)'">
		<div class="container">
			<div class="hero-text text-white">
				<h2>Get The Best Quality Online Courses</h2>
				<p>Online/Personal Training IT & Soft Skills Courses provided by 11+ Years of Industry Trained Corporate Professionals <br> In case of Team Upliftment we also have special and Flexible deals for you</p>
			</div>
			<div class="row">
				<div class="col-lg-10 offset-lg-1">
					<form class="intro-newslatter">
						<input id="studentname" type="text" placeholder="FirstName">
							<input id="studentemail" type="text" class="last-s" placeholder="E-mail">
							<button (click)="RegisterStudent()" class="site-btn">Sign Up Now</button>
					</form>

				</div>
			</div> 
		</div>
	</section> -->
	<!-- Hero section end -->
	<!-- search section -->
	<!-- <section class="search-section">
		<div class="container">
			<div class="search-warp">
				<div class="section-title text-white">
					<h2>Register Now & Get Started !</h2>
				</div>
				<div class="row">
					<div class="col-md-10 offset-md-1">
						<!-- search form -->
						<!--<form class="course-search-form">
							<input id="studentname" type="text" placeholder="FirstName">
							<input id="studentemail" type="text" class="last-s" placeholder="E-mail">
							<button (click)="RegisterStudent()" class="site-btn">Sign Up Now</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section> -->
	<!-- search section end -->


	<!-- course section -->
	<section class="course-section">
			<div class="section-title mb-0">
				<h2>Personal Training Courses</h2>
				<p>Don't like online video courses ? Want to have it under expert guidance and supervision<br> We have a huge range of full stack development and corporate get ready courses at the ease of Training from Zoom</p>
			</div>
		<div class="course-warp">
			<ul class="course-filter controls">
				<li class="control active" data-filter="all">All</li>
				<li class="control" data-filter=".soft">{{this.soft}}</li>
				<li class="control" data-filter=".design">{{this.design}}</li>
				<li class="control" data-filter=".web">{{this.web}}</li>
				<li class="control" data-filter=".db">{{this.db}}</li>
			</ul>                                       
			<div class="row course-items-area">
				<!-- course -->
				<div class="mix col-lg-3 col-md-4 col-sm-6 soft">
					<div class="course-item">
						<div class="course-thumb set-bg" [style.background-image]="'url(assets/img/courses/1.jpg)'">
							<div class="price">Price: $15</div>
						</div>
						<div class="course-info">
							<div class="course-text">
								<h5>{{this.softCourse1}}</h5>
								<p>{{this.softCourse1desc}}</p>
								<div class="students">90 Students</div>
							</div>
							<div class="course-author">
								<div class="ca-pic set-bg" [style.background-image]="'url(assets/img/authors/1.jpg)'"></div>
								<p>{{this.lecturer}}, <span>Developer</span></p>
							</div>
						</div>
					</div>
				</div>
				<!-- course -->
				<div class="mix col-lg-3 col-md-4 col-sm-6 design">
					<div class="course-item">
						<div class="course-thumb set-bg" [style.background-image]="'url(assets/img/courses/2.jpg)'">
							<div class="price">Price: $15</div>
						</div>
						<div class="course-info">
							<div class="course-text">
								<h5>{{this.designCourse1}}</h5>
								<p>{{this.designCourse1desc}}</p>
								<div class="students">30 Students</div>
							</div>
							<div class="course-author">
								<div class="ca-pic set-bg" [style.background-image]="'url(assets/img/authors/2.jpg)'"></div>
								<p>{{this.lecturer}}, <span>Developer</span></p>
							</div>
						</div>
					</div>
				</div>
				<!-- course -->
				<div class="mix col-lg-3 col-md-4 col-sm-6 web">
					<div class="course-item">
						<div class="course-thumb set-bg" [style.background-image]="'url(assets/img/courses/3.jpg)'">
							<div class="price">Price: $15</div>
						</div>
						<div class="course-info">
							<div class="course-text">
								<h5>{{this.webCourse1}}</h5>
								<p>{{this.webCourse1desc}}</p>
								<div class="students">65 Students</div>
							</div>
							<div class="course-author">
								<div class="ca-pic set-bg" [style.background-image]="'url(assets/img/authors/3.jpg)'"></div>
								<p>{{this.lecturer}}, <span>Developer</span></p>
							</div>
						</div>
					</div>
				</div>
				<!-- course -->
				<div class="mix col-lg-3 col-md-4 col-sm-6 db">
					<div class="course-item">
						<div class="course-thumb set-bg" [style.background-image]="'url(assets/img/courses/4.jpg)'">
							<div class="price">Price: $15</div>
						</div>
						<div class="course-info">
							<div class="course-text">
								<h5>{{this.dbCourse1}}</h5>
								<p>{{this.dbCourse1desc}}</p>
								<div class="students">120 Students</div>
							</div>
							<div class="course-author">
								<div class="ca-pic set-bg" [style.background-image]="'url(assets/img/authors/4.jpg)'"></div>
								<p>{{this.lecturer}}, <span>Developer</span></p>
							</div>
						</div>
					</div>
				</div>
				<!-- course -->
				<div class="mix col-lg-3 col-md-4 col-sm-6 soft">
					<div class="course-item">
						<div class="course-thumb set-bg" [style.background-image]="'url(assets/img/courses/5.jpg)'">
							<div class="price">Price: $15</div>
						</div>
						<div class="course-info">
							<div class="course-text">
								<h5>{{this.softCourse2}}</h5>
								<p>{{this.softCourse2desc}}</p>
								<div class="students">61 Students</div>
							</div>
							<div class="course-author">
								<div class="ca-pic set-bg" [style.background-image]="'url(assets/img/authors/5.jpg)'"></div>
								<p>{{this.lecturer}}, <span>Developer</span></p>
							</div>
						</div>
					</div>
				</div>
				<!-- course -->
				<div class="mix col-lg-3 col-md-4 col-sm-6 design">
					<div class="course-item">
						<div class="course-thumb set-bg" [style.background-image]="'url(assets/img/courses/6.jpg)'">
							<div class="price">Price: $15</div>
						</div>
						<div class="course-info">
							<div class="course-text">
								<h5>{{this.designCourse2}}</h5>
								<p>{{this.designCourse2desc}}</p>
								<div class="students">120 Students</div>
							</div>
							<div class="course-author">
								<div class="ca-pic set-bg" [style.background-image]="'url(assets/img/authors/6.jpg)'"></div>
								<p>{{this.lecturer}}, <span>Developer</span></p>
							</div>
						</div>
					</div>
				</div>
				<!-- course -->
				<div class="mix col-lg-3 col-md-4 col-sm-6 web">
					<div class="course-item">
						<div class="course-thumb set-bg" [style.background-image]="'url(assets/img/courses/7.jpg)'">
							<div class="price">Price: $15</div>
						</div>
						<div class="course-info">
							<div class="course-text">
								<h5>{{this.webCourse2}}</h5>
								<p>{{this.webCourse2desc}}</p>
								<div class="students">90 Students</div>
							</div>
							<div class="course-author">
								<div class="ca-pic set-bg" [style.background-image]="'url(assets/img/authors/7.jpg)'"></div>
								<p>{{this.lecturer}}, <span>Developer</span></p>
							</div>
						</div>
					</div>
				</div>
				<!-- course -->
				<div class="mix col-lg-3 col-md-4 col-sm-6 db">
					<div class="course-item">
						<div class="course-thumb set-bg" [style.background-image]="'url(assets/img/courses/8.jpg)'">
							<div class="price">Price: $15</div>
						</div>
						<div class="course-info">
							<div class="course-text">
								<h5>{{this.dbCourse2}}</h5>
								<p>{{this.dbCourse2desc}}</p>
								<div class="students">20 Students</div>
							</div>
							<div class="course-author">
								<div class="ca-pic set-bg" [style.background-image]="'url(assets/img/authors/8.jpg)'"></div>
								<p>{{this.lecturer}}, <span>Developer</span></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- course section end -->

