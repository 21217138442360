import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { AccountService} from '../account/shared/account.service';
// used to create fake backend
//import { fakeBackendProvider } from '../shared/fake.backend';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor} from '../shared/jwt.interceptor';
import { ErrorInterceptor} from '../shared/error.interceptor';
import { AppComponent } from './app.component';
import { AlertComponent } from '../shared/alert/alert.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CartComponent } from '../cart/cart.component';
import { HomeComponent } from '../home/home.component';
import { AccountModule } from '../account/account.module'
import { AccountService } from 'src/account/shared/account.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule} from '../modal/modal.module'
import { FormsModule } from '@angular/forms';

declare var $: any;

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        AccountModule,
        NgxPaginationModule,
        ModalModule,
        BrowserAnimationsModule,
        FormsModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        CartComponent,
        HomeComponent
    ],
    providers: [
        //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {provide: AccountService}
        // provider used to create fake backend
        //fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };