import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AccountService } from '../account/shared/account.service';
import { User } from '../models/user';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/shared/alert-service.component';


@Component({ selector: 'app', templateUrl: 'app.component.html', styleUrls:['app.component.css'] })
export class AppComponent {
    user: User;
    copyright='';
    copyrightlink = '';
    Address1 = '';
    Address2='';
    Phone = '';
    Email = '';

    name:string;
    email:string;

    constructor(@Inject(DOCUMENT) document, private accountService: AccountService, private alertService: AlertService,private route: ActivatedRoute,
    private router: Router) {
      //this.accountService.user.subscribe(x => this.user = x);
      //this.user = JSON.parse(localStorage.getItem('user'));
      if (this.accountService.userValue) {
        this.user = this.accountService.userValue;
        }
        this.copyright = environment.copyright;
        this.copyrightlink= environment.copyrightlink;
        this.Address1 = environment.Address1;
        this.Address2 = environment.Address2;
        this.Phone = environment.Phone;
        this.Email  = environment.Email;
        
    }


    logout() {
        this.accountService.logout();
        this.router.navigate(['/']);
        window.location.reload();
    }
     
    ngOnIntit(){


    }

    RegisterTeacher(){
        debugger;
        this.name = (<HTMLInputElement>document.getElementById('teachername')).value;
        this.email = (<HTMLInputElement>document.getElementById('teacheremail')).value;
        this.router.navigate(['/register/'+this.name + '/'+ this.email]);
      }
}