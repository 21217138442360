export const environment = {
  production: true,
  environmentName: 'aalim',
  apiUrl: 'https://api.spidosoft.com',
  imageURL: 'https://api.spidosoft.com/Resources/Images/',
  stripe_key: 'pk_test_51JLce1SHNr451wa0Csrf8Gas5mmsvzedlV1xDyA7ujOaqHaAMpMXLueJUPdu9EsMW3PxCJhTbvekwrDOlTwmaEGC00RbvjSziN',
  secrtetkey: 'sk_test_51JLce1SHNr451wa0fwP0II9IkKcuNfobYCF0z0kOXeD5hQn6UWr4nmPbCHT4oOPF8uoT8jf9rFy3R1akUceTvNCF00GP1pVIRp',
  appURL: 'https://academy.spidosoft.com',
  soft: 'Soft Skills',
  design: 'UI Design',
  web: 'Backend Development',
  db :'Database Development',
  softCourse1: 'Corporate World & Geeta',
  designCourse1:'Angular 7+',
  webCourse1:'MVC, Web API (Core)',
  dbCourse1:'SQL, PL/SQL',
  softCourse2:'Stress Management',
  designCourse2:'Javascript',
  webCourse2:'C# Basics To Advanced',
  dbCourse2:'Snowflake',
  softCourse1desc: 'Testing & RPA',
  designCourse1desc:'From Novice To Ninja',
  webCourse1desc:   'Complete Bak End Development Course',
  dbCourse1desc:    'Become a Database Expert',
  softCourse2desc:  'Automated Testing Programming',
  designCourse2desc:'Be a UI Expert in base Technology',
  webCourse2desc:   'Learn Basics and Advanced of C#',
  dbCourse2desc:    'Master the Data Warehouse Management',
  lecturer:'Aalim Mansuri',
  copyright: 'Aalims Academy',
  copyrightlink: 'https://academy.spidosoft.com',
  Address1: 'Pune',
  Address2:'Maharashtra, India',
  Phone:'+91-866 886 4700',
  Email:'me@aalimmansuri.in'
};