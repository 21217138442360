<section class="sa">
    <div class="container sa-title__section">
        <div class="sa-title__background" style="background-image: url('./assets/img/about-us.jpg');"></div>
        <div class="sa__title-container">
            <h2 class="sa__title">We share knowledge with the world</h2>
        </div>
    </div>
</section>
<hr />
<div class="container text-center">
    <h1>Changing learning for the better</h1>
    <p>
        Whether you want to learn or to share what you know, you’ve come to the right place. As a global destination for online learning, we connect people through knowledge.
    </p>
    <p>
        We help organizations of all types and sizes prepare for the path ahead - wherever it leads. Our curated collection of business and technical courses help companies, governments, and nonprofits go further by placing learning at the center of their strategies.
    </p>
</div>
