import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'privacy',
    templateUrl: 'privacy.component.html',
    styleUrls: ['privacy.component.css']
  })

export class PrivacyComponent {
    copyright= '';
    copyrightlink = '';

    constructor(@Inject(DOCUMENT) document, private router: Router) {
    this.copyright= environment.copyright;
    this.copyrightlink = environment.copyrightlink;
  }
}
