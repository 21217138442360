<!-- main app container -->

	<!-- Header section -->
<nav class="navbar navbar-expand-md navbar-light navbar-fixed-top" style="background: #d8d8d8;">
	<div class="container">
		<div class="navbar-header">
			<a href="/" class="navbar-brand">
				<!-- <img src="/src/assets/img/logo.png" /> -->
				{{this.copyright}}
			</a>
		</div>
		<button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
			<span class="navbar-toggler-icon"></span>
		</button>

		<div class="collapse navbar-collapse" id="navbarCollapse">
			<div class="nav navbar-nav">
				<a href="/" style="color:black;font-weight: bold;" class="nav-item nav-link active">Home</a>
				<a href="/about" style="color:black;font-weight: bold;" class="nav-item nav-link">About</a>
				<a href="/users" *ngIf="user" style="color:black;font-weight: bold;" class="nav-item nav-link">Users</a>
				<a href="/courses" *ngIf="user" style="color:black;font-weight: bold;" class="nav-item nav-link">Courses</a>
				<a href="/testimonials" style="color:black;font-weight: bold;" class="nav-item nav-link">Testimonials</a>
			</div>
			<div class="nav navbar-nav ml-auto">
				
				<a href="/login" *ngIf="!user" class="nav-item nav-link" title="Login">
					<i  class="fa fa-sign-in" aria-hidden="true" style="font-size: 25px; color:#00721c;"></i>
				</a>
				<a (click)="logout()" *ngIf="user" class="nav-item nav-link" title="Log Out">
					<i  class="fa fa-sign-out" aria-hidden="true" style="font-size: 25px; color:#d82a4e;"></i>
				</a>
				
				
				<a *ngIf="user" href="/cart" class="nav-item nav-link" title="Cart">
					<i  class="fa fa-cart-plus" aria-hidden="true" style="font-size: 25px; color:#d82a4e;"></i>
				</a>
				<a *ngIf="!user" href="/login" class="nav-item nav-link" title="Cart">
					<i class="fa fa-cart-plus" aria-hidden="true" style="font-size: 25px; color:#d82a4e;"></i>
				</a>

				<!-- <button mat-fab color="warn" *ngIf="user"><a style="color:red;font-weight: bold;"  href="/cart">Cart</a></button>
				<button mat-fab color="warn" *ngIf="!user"><a style="color:red;font-weight: bold;"  href="/login">Cart</a></button> -->

			</div>
		</div>
	</div>
</nav>
<hr />
	<!-- Header section end -->
<div class="app-container" style="clear: both;" [ngClass]="{ 'bg-light': user }">
    <alert></alert>
    <div class="main-container">
		<!-- placeholder for the header -->
		<div class="container-fluid">
			<section>
				<router-outlet></router-outlet>
			</section>
		</div>
	</div>
</div>

<hr />
<!--Bottom htmls From index.html -->
<!-- signup section -->
<section class="signup-section spad">
	<div class="signup-bg set-bg" [style.background-image]="'url(assets/img/signup-bg.jpg)'"></div>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-6">
				<div class="signup-warp">
					<div class="section-title text-white text-left">
						<h2>Become an instructor</h2>
						<p>Instructors from around the world teach millions of students with us. We provide the tools and skills to teach what you love.</p>
					</div>
					<div class="text-center">
						<a href="/register" class="btn btn-success">Register Now</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<hr />
<!-- footer section -->
<footer class="pb-0">
	<div class="footer-top">
		<div class="footer-warp">
			<div class="row">
				<div class="widget-item">
					<h4>Contact Info</h4>
					<ul class="contact-list">
						<li>{{this.Address1}} <br>{{this.Address2}}</li>
						<li>{{this.Phone}}</li>
						<li>{{this.Email}}</li>
					</ul>
				</div>
				<div class="widget-item">
					<h4>Engineering</h4>
					<ul>
						<li><a href="">Applied Studies</a></li>
						<li><a href="">Web Design & Development</a></li>
						<li><a href="">Software ReEngineering</a></li>
						<li><a href="">IT Consulting</a></li>
						<li><a href="">Mobile App Development</a></li>
					</ul>
				</div>
				<div class="widget-item">
					<h4>Soft Skills</h4>
					<ul>
						<li><a href="">Corporate Ethics</a></li>
						<li><a href="">Stress Management</a></li>
						<li><a href="">Professional Coach</a></li>
						<li><a href="">Leadership Skills</a></li>
						<li><a href="">Per Scriptures</a></li>
					</ul>
				</div>
				<div class="widget-item">
					<h4>Applied Coaching</h4>
					<ul>
						<li><a href="">Applied Studies</a></li>
						<li><a href="">Computer Engineering</a></li>
						<li><a href="">Software Engineering</a></li>
						<li><a href="">Informational Engineering</a></li>
						<li><a href="">Database Engineering</a></li>
					</ul>
				</div>
				<div class="widget-item">
					<h4>Newsletter</h4>
					<div class="form-group">
						<input type="email" placeholder="E-mail" class="form-control">
					</div>	
						<button class="btn btn-danger">Subscribe</button>
						<p>*We don’t spam</p>
				</div>
			</div>
		</div>
	</div>
	<div class="footer-bottom">
		<div class="footer-warp">
			<ul class="footer-menu">
				<li><a href="/terms">Terms & Conditions</a></li>
				<li><a href="#">Register</a></li>
				<li><a href="/privacy">Privacy</a></li>
			</ul>
			<div class="copyright">
				Copyright &copy; <script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i class="fa fa-heart-o" aria-hidden="true"></i> by <a href="{{this.copyrightlink}}" target="_blank">{{this.copyright}}</a>
			</div>
		</div>
	</div>
</footer> 
<!-- footer section end -->