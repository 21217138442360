import { Component} from '@angular/core';

@Component({
    selector: 'testimonials',
    templateUrl: 'testimonials.component.html',
     styleUrls: ['testimonials.component.css']
  })

  export class TestimonialsComponent {

  }