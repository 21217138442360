import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/account/shared/account.service';
import { Course } from 'src/models/course';
import { AlertService } from 'src/shared/alert-service.component';

@Component({
  selector: 'cart-success',
  templateUrl: './success.component.html'
})

export class SuccessComponent implements OnInit {

  constructor(private _Activatedroute:ActivatedRoute,
              private _router:Router, private alertService: AlertService,private accountService: AccountService) { 
  }

   sub;
   id : string;
   sessionBefore: string;
   buyed: Course;

  ngOnInit(): void {

    this.sub=this._Activatedroute.paramMap.subscribe(params => { 
       this.id = params.get('sessionid'); 
    });

    this.sessionBefore = localStorage.getItem('sessionId');
    debugger;
    if(this.sessionBefore==this.id){
            //Add courses to User From Backend
            this.alertService.success('Validate Successful');
            localStorage.setItem('cartCourseList', JSON.stringify([]));
            localStorage.removeItem('sessionId');

            this.buyed = JSON.parse(localStorage.getItem('buyed'));

            this.accountService.buyed(this.accountService.userValue.id, this.buyed).subscribe();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}